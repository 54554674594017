(function() { 'use strict';

  // Const
  // -----
  window.HEADER_HEIGHT = $('.header').height();

  // selectors
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  // Hover elements
  // -----
  (function() { // Чтобы потом с тачем не запариваться
    var $menus = $('.js-menu'),
        $categories = $('.product-categories');

    if( IS_MOBILE ) {
      $menus.on('click.js-menu', 'a', function(e) {
        e.preventDefault();

        var $anchor = $(this);
        var $parent = $anchor.parent();

        var isWithDropdown = $parent.hasClass('with-dropdown');
        var isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings().removeClass('is-hovered');

        if (!isWithDropdown) {
          location.href = $anchor.attr('href');
        } else {
          if (isOnHover) {
            location.href = $anchor.attr('href');
          } else {
            $parent.addClass('is-hovered');
          }
        }
      });

      $categories.on('click.product-categories', '.product-category', function(e) {
        e.preventDefault();

        $(this).addClass('is-hovered').siblings().removeClass('is-hovered');
      });
    }
  }());


  // Scroll to
  // ---------
  $DOCUMENT.on('click', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this),
        $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
        speed = $lnk.data('scrollSpeed') || 150,
        offset = $lnk.data('scrollOffset') || 0;

    $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
  });


  // Scrolling to top
  // ----------------
  if( !IS_MOBILE ) {
    var goTopBtn = $('#go-top-button');

    goTopBtn.click(function() {
      $WINDOW.scrollTo(0, 200);
    });

    $WINDOW.on('scroll', function(ev, goTopBtnClass) {
      var scrollTop = $WINDOW.scrollTop();

      if ( goTopBtnClass == undefined )
        goTopBtnClass = 'top-button-visible';

      if ( scrollTop > HEADER_HEIGHT ) {
        goTopBtn.addClass(goTopBtnClass);
      } else {
        goTopBtn.removeClass();
      }
    }).trigger('scroll', ['top-button-custom-visible']);
  }

  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    margin: 0,

    infobar: true,
    buttons: true,

    slideShow : false,
    fullScreen: false,
    thumbs: false,
    closeBtn: true,

    smallBtn: false,

    image : {
      protect : true
    },

    focus: true,
    focusElement: '.js-autofocus-inp'
  });

  $('.fancybox').fancybox();


  // Callbacks
  // ---------
  CALLBACKS['reachGoalPhoneOrder'] = function() {
    if( typeof yaCounter45102267 == 'object' )
      yaCounter45102267.reachGoal('phone_order');
  };

})();